import * as React from 'react';
import { Dialog, DialogTitle, DialogContent, useMediaQuery, useTheme, DialogActions, Button, Stack, Chip, Pagination, FormControlLabel, Switch, TextField, Grid, Alert, Typography, IconButton, Stepper, Step, StepLabel, StepContent, ListItem, ListItemText, Box, List } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { useDispatch, useSelector } from 'react-redux';
import { getNotificationPermission, requestPermission, setShowAutomaticSelection, setShowIosInstallation, setShowLoading, setShowNumbers, setShowPaymentOptions } from '@features/app/_appSlice';
import { addChosen, addTaken, freeChosen, listeningChosen, resetTakenStatus, setAutomaticPending, setId, setPending, setSelectedNumbers, setSelectedStatus, setStep, updateChosen } from './chooseSlice';
import { enqueueSnackbar } from 'notistack';
import { ChooseContactData } from 'features/choose';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import ClearAllIcon from '@mui/icons-material/ClearAll';

const steps = ['Selección de números', 'Información de contacto', 'Pagar'];

export default function Choose() {
    const pageItems = 100;
    const dispatch = useDispatch();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const open = useSelector(state => state.app.showNumbers);
    const permissionStatus = useSelector(state => state.app.permissionStatus);
    const chooseState = useSelector(state => state.choose);
    const eventState = useSelector(state => state.event);
    const eventNumbersStatus = useSelector(state => state.event.numbersStatus);
    const chooseStatus = useSelector(state => state.choose.status);
    const chosenStatus = useSelector(state => state.choose.chosenStatus);
    const automaticChosenStatus = useSelector(state => state.choose.automaticChosenStatus);
    const takenStatus = useSelector(state => state.choose.addTakenStatus);
    const selectedNumbers = useSelector(state => state.choose.selectedNumbers);
    const { userData } = useSelector(state => state.chooseContactData);
    const appState = useSelector(state => state.app);

    const [numbersEntries, setNumbersEntries] = React.useState(eventState.numbers);
    const [showAvailable, setShowAvailable] = React.useState(true);
    const [canContinue, setCanContinue] = React.useState(false);
    const [searchNumeration, setSearchNumeration] = React.useState('');
    const [maxWidth, setMaxWidth] = React.useState('lg');
    const [chosenNumber, setChosenNumber] = React.useState('');

    const [page, setPage] = React.useState(1);
    const [pages, setPages] = React.useState(1);
    const [activeStep, setActiveStep] = React.useState(0);

    const handleChange = (_, value) => { setPage(value); };

    const handleSelected = async (number) => {
        dispatch(setShowLoading(true));
        setChosenNumber(number);
        dispatch(addChosen(number))
    }

    const handleRemove = (number) => {
        const n = selectedNumbers.filter(n => n !== number);
        dispatch(freeChosen(number));
        dispatch(setSelectedNumbers(n));
    }

    const onClose = () => {
        dispatch(setId());
        dispatch(setShowNumbers(false));
        dispatch(setSelectedNumbers([]));
        dispatch(resetTakenStatus());
        setShowAvailable(true);
        setSearchNumeration('');
        setPage(1);
        setCanContinue(false);
        setActiveStep(0);
    }

    const onContinue = () => {
        setActiveStep(step => step + 1);
    }

    const onConfirm = () => {
        dispatch(addTaken({
            numbers: selectedNumbers,
            userInfo: userData,
            total: selectedNumbers.length * eventState.tickets.price
        }));
    }

    const handleRequestPermission = () => {
        if (appState.isIos && !appState.isStandalone && getNotificationPermission() !== 'granted')
            dispatch(setShowIosInstallation(true));
        else
            dispatch(requestPermission());
    }

    const handleShowPaymentOptions = () => dispatch(setShowPaymentOptions(true));

    const handleClearSelection = () => {
        dispatch(setSelectedNumbers([]));
        dispatch(updateChosen([]));
    }

    React.useEffect(() => {
        if (activeStep !== 1) return
        if (takenStatus === 'pending') dispatch(setShowLoading(true));
        else {
            if (takenStatus === 'fulfilled') setActiveStep(step => step + 1);
            dispatch(setShowLoading(false));
        }
    }, [activeStep, takenStatus, dispatch])

    React.useEffect(() => {
        if (!open) return;
        if (chooseStatus === 'idle') {
            dispatch(setShowLoading(true));
            dispatch(listeningChosen());
        }
        if (chooseStatus === 'listening' && eventNumbersStatus === 'succeeded')
            dispatch(setShowLoading(false));
    }, [open, eventNumbersStatus, chooseStatus, eventState.id, dispatch])

    React.useEffect(() => {
        if (chosenStatus === 'pending') return;
        dispatch(setPending());
        if (chosenStatus === 'success') {
            const j = {};
            [...selectedNumbers, chosenNumber].forEach(n => j[n] = true);
            let n = Object.keys(j).map(n => n).filter(n => n !== '').sort();
            dispatch(setSelectedNumbers(n));
            dispatch(updateChosen(n));
            dispatch(setSelectedStatus('selecting'));
            dispatch(setShowLoading(false));
        }
        if (chosenStatus === 'rejected') {
            dispatch(setShowLoading(false));
            if (chosenNumber)
                enqueueSnackbar(`Número ${chosenNumber} no disponible.`, { variant: 'warning' });
        }
        dispatch(listeningChosen());
    }, [selectedNumbers, chosenStatus, chosenNumber, dispatch]);

    React.useEffect(() => {
        if (automaticChosenStatus === 'pending') return;
        dispatch(setAutomaticPending());
        if (automaticChosenStatus === 'success') {
            const j = {};
            [...selectedNumbers].forEach(n => j[n] = true);
            let n = Object.keys(j).map(n => n).filter(n => n !== '').sort();
            dispatch(setSelectedNumbers(n));
            dispatch(updateChosen(n));
            dispatch(setSelectedStatus('selecting'));
            dispatch(setShowLoading(false));
        }
        if (automaticChosenStatus === 'rejected') {
            dispatch(setShowLoading(false));
        }
        dispatch(listeningChosen());
    }, [selectedNumbers, automaticChosenStatus, dispatch]);

    React.useEffect(() => {
        setMaxWidth(activeStep === 0 ? 'lg' : 'sm');
        dispatch(setStep(activeStep));

        if (activeStep === 0) {
            const min = eventState.min ?? 1;
            setCanContinue(selectedNumbers.length >= min);
            if (eventState.max && selectedNumbers.length > min) setCanContinue(selectedNumbers.length <= eventState.max);
        }
    }, [activeStep, eventState.min, selectedNumbers, dispatch]);

    React.useEffect(() => {
        let entries = showAvailable ? eventState.numbers.filter(([_, available]) => available) : eventState.numbers;

        if (searchNumeration && searchNumeration > '')
            entries = entries.filter(([number,]) => number.match(`${searchNumeration}`));

        const pages = Math.ceil(entries.length / pageItems);
        setNumbersEntries(entries);
        setPages(pages);

        if (pages && page > pages) setPage(pages);
    }, [searchNumeration, showAvailable, eventState.numbers, page]);

    React.useEffect(() => {
        if (activeStep !== 1) return;
        setCanContinue([
            userData.name.trim().length > 0,
            userData.lastName.trim().length > 0,
            userData.phone.trim().length == 10,
            userData.state.trim().length > 0
        ].every(areTrue => areTrue))
    }, [activeStep, userData]);

    return (<Dialog
        open={open}
        fullScreen={fullScreen}
        fullWidth
        maxWidth={maxWidth}
    >
        <DialogTitle>Compra</DialogTitle>
        <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8
            }}
        >
            <CloseIcon />
        </IconButton>
        <DialogContent sx={{ pt: 0 }}>
            <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((label, index) => (
                    <Step key={index}>
                        <StepLabel>
                            {label}
                        </StepLabel>
                        <StepContent>
                            {index === 0 && <Grid container spacing={{ xs: 0, md: 2 }} direction={{ xs: 'column-reverse', md: 'row' }}>
                                <Grid item xs={12} md={7}>
                                    <Stack sx={{ mb: 2 }} direction="row" justifyContent="space-between" alignItems="baseline">
                                        <TextField value={searchNumeration} onChange={(e) => setSearchNumeration(e.target.value)} fullWidth label="Buscar numeración" margin="normal" type="number" />
                                        <FormControlLabel
                                            sx={{ minWidth: 190 }}
                                            value="start"
                                            control={<Switch id="onlyAvailable" color="primary" checked={showAvailable} onChange={(e) => setShowAvailable(e.target.checked)} />}
                                            label="Solo disponibles"
                                            labelPlacement="start"
                                        />
                                    </Stack>
                                    <Stack spacing={0} alignItems="center" sx={{ mb: 2 }}>
                                        {pages > 0 && <Pagination color="primary" count={pages} page={page} onChange={handleChange} size={fullScreen ? 'small' : 'normal'} />}
                                    </Stack>
                                    {eventNumbersStatus !== 'succeeded' && <Alert severity="info">Cargando información</Alert>}
                                    {eventNumbersStatus === 'succeeded' && pages === 0 && <Alert severity="warning">Sin resultados</Alert>}
                                    <Stack direction="row" spacing={1} useFlexGap flexWrap="wrap" justifyContent="space-between"
                                        alignItems="flex-start">
                                        {numbersEntries.slice((page - 1) * pageItems, (page) * pageItems).map(([number, available], _) =>
                                            <Chip disabled={!available || selectedNumbers.indexOf(number) >= 0} key={number} label={number} color="primary" onClick={() => handleSelected(number)} />
                                        )}
                                    </Stack>
                                </Grid>
                                <Grid item xs={12} md={5}>
                                    <Box sx={{ flexGrow: 1 }}>
                                        {!fullScreen && <Stack alignItems='center' sx={{ mb: 2 }}>
                                            <Typography variant='h6' color={'text.secondary'}>Números seleccionados</Typography>
                                        </Stack>}
                                        {selectedNumbers.length === 0 && <>
                                            <Alert severity="info">Aun no ha seleccionado números. <br />
                                                También puede utilizar nuestra selección automática <AutoFixHighIcon />
                                            </Alert>

                                        </>}
                                        <Stack direction="row" spacing={0.2} useFlexGap flexWrap="wrap" justifyContent="space-between"
                                            alignItems="flex-start">
                                            {selectedNumbers.map(number =>
                                                <Chip onDelete={() => handleRemove(number)} key={number} label={number} variant='outlined' color="primary" />
                                            )}
                                            {!canContinue && selectedNumbers.length > 0 && eventState.min && eventState.min > selectedNumbers.length && <Alert severity="warning">Se requieren mínimo {eventState.min} números para continuar.</Alert>}
                                            {!canContinue && selectedNumbers.length > 0 && eventState.max && eventState.max < selectedNumbers.length && <Alert severity="warning">Puede seleccionar máximo {eventState.max} números, elimine unos para continuar.</Alert>}
                                        </Stack>
                                        <Stack direction="row" spacing={1} justifyContent="flex-end">
                                            <IconButton color="primary" onClick={() => dispatch(setShowAutomaticSelection(true))}>
                                                <AutoFixHighIcon />
                                            </IconButton >
                                            <IconButton color="primary" onClick={handleClearSelection}>
                                                <ClearAllIcon />
                                            </IconButton >
                                        </Stack>
                                    </Box>
                                    {selectedNumbers.length !== 0 &&
                                        <Box sx={{ flexGrow: 0 }}>
                                            <List disablePadding>
                                                <ListItem sx={{ pt: 1, px: 0 }}>
                                                    <ListItemText primary={`${selectedNumbers.length} x ${eventState.tickets.price.toFixed(2)}`} />
                                                    <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                                                        $ {(selectedNumbers.length * eventState.tickets.price).toFixed(2)} {eventState.currency ?? 'MXN'}
                                                    </Typography>
                                                </ListItem>
                                            </List>
                                        </Box>}
                                </Grid>
                            </Grid>}
                            {index === 1 && <ChooseContactData />}
                            {index === 2 && <Grid item xs={12}>
                                <Stack direction='row' justifyContent="center" alignItems="center" spacing={2}>
                                    <DoneIcon color='primary' />
                                    <Typography variant='h6' color={'text.secondary'}>Números separados</Typography>
                                </Stack>
                                {permissionStatus !== 'granted' &&
                                    <>
                                        <Alert severity="warning">Activa las notificaciones para enterarte del estatus de tu referencia, si ganaste, o están por vencer tus números <Button size="small" color="primary" variant="contained" onClick={handleRequestPermission}>Activar</Button></Alert>
                                    </>
                                }
                                <Alert severity="info">
                                    Referencia de pago: <Typography component="b" variant="b" sx={{ letterSpacing: 2 }} children={<h1>test</h1>}>{chooseState.reference}</Typography>
                                    <br />
                                    Total: <Typography component="b" variant="b" sx={{ letterSpacing: 2 }}>$ {(selectedNumbers.length * eventState.tickets.price).toFixed(2)} {eventState.currency ?? 'MXN'}</Typography>
                                </Alert>
                                <Stack alignContent={'center'} direction={'column'}>
                                    <Button variant='contained' color='primary' onClick={handleShowPaymentOptions}>PAGAR</Button>
                                </Stack>
                            </Grid>}
                        </StepContent>
                    </Step>
                ))}
            </Stepper>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
            <Button onClick={onClose} variant='outlined'>Salir</Button>
            {activeStep === 1 && <Button onClick={() => setActiveStep(s => s - 1)} variant='outlined'>Regresar</Button>}
            {activeStep === 0 && <Button onClick={onContinue} disabled={!canContinue} variant='contained' color='primary'>Continuar</Button>}
            {activeStep === 1 && <Button onClick={onConfirm} disabled={!canContinue} variant='contained' color='primary'>Confirmar</Button>}
        </DialogActions>
    </Dialog >);
}