
import * as React from 'react';
import Lottie from "lottie-react";
// import loadingAnimation from "./../../lottie/loading.json";
// import loadingAnimation from "./../../lottie/loading-green.json";
import loadingAnimation from "./../../lottie/loading-white.json";
import { useSelector } from 'react-redux'
import { Backdrop, Box } from '@mui/material';

const Loading = () => {
    const appShowLoading = useSelector(state => state.app.showLoading);
    const loadingSrc = useSelector(state => state.app.loading);
    return (
        <Backdrop
            open={appShowLoading}
            sx={{ zIndex: 9999999 }}
        >
            <Box
                sx={{
                    width: 256,
                    height: 256,
                }}
            >
                {appShowLoading && <Lottie animationData={loadingAnimation} loop={true} />}
            </Box>
        </Backdrop >
    )
}

export default Loading