import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LandingPage, Loading, AppBar, PaymentOptions, Faq, Footer, IosInstallation, EventDetails, RandomSelection } from '@features/app';
import { Choose, Event } from '@features/event';
import { isStandalone, loadApp, requestToken, setOnLandingPage, setShowLoading } from '@features/app/_appSlice';
import { Container, Grid } from '@mui/material';
import { Separated } from '@features/numbers';
import { enqueueSnackbar } from 'notistack';


const App = () => {
  const dispatch = useDispatch();
  const appState = useSelector(state => state.app);
  const permissionStatus = useSelector(state => state.app.permissionStatus);
  const tokenId = useSelector(state => state.app.tokenId);
  const isFirstTime = useSelector(state => state.app.firstTime);
  const appStatus = useSelector(state => state.app.status);
  const appReady = useSelector(state => state.app.load);
  const eventReady = useSelector(state => state.event.load);
  const onLandingPage = useSelector(state => state.app.onLandingPage);

  React.useEffect(() => {
    if (appStatus === 'idle') dispatch(loadApp())
  }, [appStatus, dispatch])

  React.useEffect(() => {
    if (appReady && eventReady) {
      if (!isFirstTime || isStandalone) dispatch(setOnLandingPage(false));
      dispatch(setShowLoading(false))
    }
  }, [appReady, eventReady, isFirstTime, dispatch])

  React.useEffect(() => {
    if (permissionStatus === 'reject')
      enqueueSnackbar('Notificaciones desactivadas', { variant: 'warning', autoHideDuration: 1000 });
    if (permissionStatus === 'granted' && !tokenId) dispatch(requestToken());
  }, [permissionStatus, tokenId, dispatch]);

  return (
    <React.Fragment>
      {appReady && eventReady && onLandingPage && <LandingPage />}
      {!onLandingPage && <AppBar />}
      {!onLandingPage && <Event />}
      {!onLandingPage && <Container maxWidth="lg">
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid container spacing={2} item xs={12}><EventDetails /></Grid>
          <Grid item xs={12}><Faq /></Grid>
        </Grid>
      </Container>}
      {!onLandingPage && <Footer title={appState.title} description={appState.subtitle} />}
      {!onLandingPage && <PaymentOptions />}
      {!onLandingPage && <Separated />}
      {!onLandingPage && <Choose />}
      {!onLandingPage && <RandomSelection />}
      {!onLandingPage && <IosInstallation />}
      <Loading />
    </React.Fragment>
  );
}

export default App