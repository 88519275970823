import * as React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Alert, useMediaQuery, useTheme, IconButton, Accordion, AccordionSummary, Typography, AccordionDetails, Stack, Chip, FormControlLabel, Switch, Divider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useDispatch, useSelector } from 'react-redux';
import { load, setOpen, unsubscribe } from './separatedSlice';
import { CopyButton } from '@features/app';
import { hasNotifications, requestPermission, setPermission } from '@features/app/_appSlice';

export default function Separated() {
    const dispatch = useDispatch();
    const eventState = useSelector(state => state.event);
    const permissionStatus = useSelector(state => state.app.permissionStatus);
    const open = useSelector(state => state.separated.open);
    const status = useSelector(state => state.separated.status);
    const chosenItems = useSelector(state => state.separated.chosen);
    const { sessionId } = useSelector(state => state.app)
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [expanded, setExpanded] = React.useState(false);

    const shotNotificationSwitch = hasNotifications();

    const handleChange = (panel) => (_, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    React.useEffect(() => {
        if (open) dispatch(load());
        else unsubscribe();
    }, [open, dispatch])

    const onClose = () => dispatch(setOpen(false));
    const handleNotifications = (e) => {
        const enabled = e.target.checked;
        if (!enabled) dispatch(setPermission('reject'));
        else dispatch(requestPermission())
    }

    return (<Dialog
        open={open}
        onClose={onClose}
        maxWidth={"sm"}
        fullWidth
        fullScreen={fullScreen}

    >
        <DialogTitle id="scroll-dialog-title">Historial de participación</DialogTitle>
        <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8
            }}
        >
            <CloseIcon />
        </IconButton>
        <DialogContent>
            <Alert severity="info">Su historial solo es accesible desde este dispositivo <br />Su llave: <b>{sessionId}</b></Alert>
            {chosenItems?.length == 0 && <Alert severity="warning">Sin historial</Alert>}
            {chosenItems.map((item, idx) => <Accordion key={idx} expanded={expanded === `${idx}`} onChange={handleChange(`${idx}`)}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`${idx}_content`}
                    id={`chosen_${idx}`}
                    sx={{ width: '100%' }}
                >
                    <Stack direction={'row'} spacing={1} justifyContent="space-between"
                        alignItems="center">
                        <Typography>{item.numbers.length} Número{item.numbers.length == 1 ? '' : 's'}</Typography>
                        <Chip color={item.settled ? 'success' : item.paid > 0 ? 'info' : 'warning'} label={item.settled ? 'Pagado' : item.paid > 0 ? `Abonado` : 'Apartado'} />
                    </Stack>
                </AccordionSummary>
                <AccordionDetails>
                    {!item.settled && <Alert severity="info" sx={{ mb: 1 }}>
                        Referencia: <Typography component="b" variant="b" sx={{ letterSpacing: 2 }}>{item.reference} <CopyButton copy={item.reference?.toString()} message={`Referencia ${item.reference} copiada`} /></Typography>
                        <br />
                        Total: <Typography component="b" variant="b" sx={{ letterSpacing: 2 }}>$ {(item.total).toFixed(2)} {eventState.currency ?? 'MXN'}</Typography>
                        {!item.settled && item.paid > 0 && <>
                            <br />Abono: <Typography component="b" variant="b" sx={{ letterSpacing: 2 }}>$ -{(item.paid).toFixed(2)} {eventState.currency ?? 'MXN'}</Typography>
                            <Divider orientation='horizontal' sx={{ pb: 0 }} />
                            <br />Pagar: <Typography component="b" variant="b" sx={{ letterSpacing: 2 }}>$ {(item.total - item.paid).toFixed(2)} {eventState.currency ?? 'MXN'}</Typography>
                        </>}
                    </Alert>}
                    {item.settled && <Alert severity="info" sx={{ mb: 1 }}>
                        Folio: <Typography component="b" variant="b" sx={{ letterSpacing: 2 }}>{item.id} <CopyButton copy={item.id?.toString()} message={`Folio ${item.id} copiado`} /></Typography>
                    </Alert>}
                    <Stack direction="row" spacing={0.2} useFlexGap flexWrap="wrap" justifyContent="space-between"
                        alignItems="flex-start">
                        {item.numbers.map(number =>
                            <Chip key={number} label={number} variant='outlined' color="primary" />
                        )}
                    </Stack>
                </AccordionDetails>
            </Accordion>)}
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
            {shotNotificationSwitch && <FormControlLabel
                sx={{ minWidth: 190 }}
                value="start"
                control={<Switch id="onlyAvailable" color="primary" checked={permissionStatus === 'granted'} onChange={handleNotifications} />}
                label="Notificaciones"
                labelPlacement="start"
            />}
            <Button onClick={onClose}>Cerrar</Button>
        </DialogActions>
    </Dialog>)
}