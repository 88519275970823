import * as React from 'react';
import { Typography, Paper, Grid, Container, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Prizes, WantWinButton } from '@features/event';
import { setOpen as setOpenSeparated } from '../numbers/separatedSlice'

export default function EventDetails() {
    const dispatch = useDispatch();
    const eventState = useSelector(state => state.event);
    const appState = useSelector(state => state.app);



    return <>
        <Grid container item xs={12} md={8}>

            <Grid item xs={12}>
                <Paper sx={{ mt: 2, pb: 2 }}>
                    <Grid item xs={12}>
                        <Typography component='h1' variant='h4' color='primary' align='center'>{appState.title}</Typography>
                        <Typography component='p' variant='p' color='text.secondary' align='center'>Los invita a la rifa #{eventState.number}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='h6' color='text.secondary' align='center'>{eventState.subtitle}</Typography>
                        <Typography align='center'>
                            {eventState.description}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ p: 2 }}>
                        <WantWinButton />
                        <Button variant="outlined" color="primary" size="large" fullWidth sx={{ mt: { xs: 0, md: 2 }, mb: { xs: 2, md: 0 } }} onClick={() => dispatch(setOpenSeparated(true))}>
                            Historial
                        </Button>
                    </Grid>
                </Paper >
            </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
            <Paper sx={{ mt: 2, pb: 2 }}>
                <Prizes />
            </Paper >
        </Grid>
    </>

}