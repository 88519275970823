import * as React from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Alert, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { setPermission, setShowIosInstallation, setShowNumbers } from './_appSlice';

export default function IosInstallation() {
    const dispatch = useDispatch();
    const open = useSelector(state => state.app.showIosInstallation);
    const onClose = () => dispatch(setShowIosInstallation(false));

    const handleRejectNotifications = () => {
        dispatch(setPermission('rejected'));
        dispatch(setShowIosInstallation(false));
        dispatch(setShowNumbers(true));
    }

    return (<Dialog
        open={open}
        onClose={onClose}
        maxWidth='sm'
    >
        <DialogTitle></DialogTitle>
        <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8
            }}
        >
            <CloseIcon />
        </IconButton>
        <DialogContent>
            <img alt="installation instructions" style={{ width: "100%", height: "100%" }} src='/ios_installation.gif' />
            <Alert severity="info">Esta usando un dispositivo Apple, para maximizar su experiencia siga la animación para agregar la aplicación y poder ver su historial de juego, recibir notificaciones.</Alert>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
            <Button onClick={handleRejectNotifications}>No me interesa</Button>
            <Button onClick={onClose}>Cerrar</Button>
        </DialogActions>
    </Dialog>)
}