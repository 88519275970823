import { firestore } from '../../app/firebase';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { collection, doc, getDoc, getDocs, limit, onSnapshot, orderBy, query } from 'firebase/firestore';
import { enqueueSnackbar } from 'notistack';

const initialState = {
    status: 'idle',
    error: '',
    open: false,
    chosen: [],
}

let _unsubscribe;

export const unsubscribe = () => _unsubscribe && _unsubscribe();

export const load = createAsyncThunk('separated/load', async (_, { dispatch, getState }) => {
    const { app: { sessionId } } = getState();
    const chosenQry = query(
        collection(firestore, 'sessions', sessionId, 'chosen'),
        orderBy('timestamp', 'desc'),
        limit(10)
    );
    _unsubscribe = onSnapshot(chosenQry, (snapshot) => {
        const chosen = [];
        if (snapshot.empty) return { chosen };
        for (const chosenDoc of snapshot.docs) {
            const data = chosenDoc.data();
            data.timestamp = data.timestamp.toDate().getTime();
            chosen.push({ ...data, id: chosenDoc.id });
        }
        dispatch(setChosen({ chosen }));
    })
});

const slice = createSlice({
    name: 'separated',
    initialState,
    reducers: {
        setOpen: (state, action) => {
            state.open = action.payload || false;
            if (!state.open) state.status = 'idle';
        },
        setChosen: (state, action) => {
            state.chosen = action.payload.chosen;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(load.pending, state => { state.status = 'pending' })
            .addCase(load.rejected, state => { state.status = 'rejected'; enqueueSnackbar('No logramos cargar la información', { variant: 'error' }); })
            .addCase(load.fulfilled, state => { state.status = 'success' })
    }
})

export const {
    setOpen,
    setChosen,
} = slice.actions

export default slice.reducer