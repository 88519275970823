import * as React from 'react';
import { Card, Stack, Typography, LinearProgress, Box, Slider, Stepper, Step, StepLabel, StepIcon } from '@mui/material';
import { animated, useSpring } from 'react-spring';
import { useSelector } from 'react-redux';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import { eventSlice } from './_eventSlice';

export default function Prizes() {
    const eventPrizes = useSelector(state => state.event.prizes);
    return (<>
        <Box
            id="prizes"
            sx={{
                p: 2
            }}
        >
            <Stack
                direction={{ xs: 'column', sm: 'row', md: 'column' }}
                justifyContent="space-between"
                alignItems="baseline"
                spacing={2}
            >
                <Typography noWrap variant="h5">Lista de premios</Typography>
                <Typography variant="p" sx={{ mt: '0 !important' }}>Con la participación mínima requerida para su desbloqueo y agregar al premio final.</Typography>
                {Object.entries(eventPrizes).sort((a, b) => a[1] - b[1]).map(([prize, settledMin]) => <Stack key={prize} spacing={0} sx={{ mt: '0.5px !important' }}>
                    <Typography noWrap variant="h5">{prize}</Typography>
                    <Typography noWrap variant="p" gutterBottom >{settledMin}</Typography>
                </Stack>)}
            </Stack>
        </Box>
    </>)

}