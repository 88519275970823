import { firestore } from '../../app/firebase';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { collection, doc, getDoc } from 'firebase/firestore';

const initialState = {
    status: 'idle',
    error: '',
    userData: {
        name: '',
        lastName: '',
        phone: '',
        state: '',
    }
}

export const readUserData = createAsyncThunk('chosen/readUserData', async (_, { getState, rejectWithValue }) => {
    const { app: { sessionId } } = getState();
    const sessionSnapshot = await getDoc(doc(collection(firestore, 'sessions'), sessionId));
    if (!sessionSnapshot.exists()) return rejectWithValue('not found');
    const userData = sessionSnapshot.data()
    return { userData }
});

const slice = createSlice({
    name: 'contactData',
    initialState,
    reducers: {
        setUserData: (state, action) => ({
            ...state,
            userData: {
                ...state.userData,
                ...action.payload
            }
        })
    },
    extraReducers(builder) {
        builder.addCase(readUserData.pending, (state) => { state.status = 'pending' })
        builder.addCase(readUserData.rejected, (state) => { state.status = 'rejected' })
        builder.addCase(readUserData.fulfilled, (state, action) => ({
            ...state,
            ...action.payload
        }))
    }
})

export const {
    setUserData,
} = slice.actions

export default slice.reducer