import * as React from 'react';
import { Button } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux'
import { getNotificationPermission, setShowIosInstallation, setShowNumbers } from '@features/app/_appSlice';


export default function WantWinButton() {
    const dispatch = useDispatch();
    const appState = useSelector(state => state.app);

    const handleWantWin = () => {
        if (appState.isIos && !appState.isStandalone && getNotificationPermission() !== 'granted')
            dispatch(setShowIosInstallation(true));
        else dispatch(setShowNumbers(true));
    }

    return <Button variant="contained" color="primary" size="large" fullWidth sx={{ mt: { xs: 0, md: 2 }, mb: { xs: 2, md: 0 } }} onClick={handleWantWin}>
        Quiero ganar
    </Button>
}