import * as React from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Grid, TextField, MenuItem } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { setShowAutomaticSelection, setShowLoading } from './_appSlice';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { automaticChosen } from '@features/event/chooseSlice';

const automaticOptions = [1, 2, 5, 10, 25, 50, 100];

export default function RandomSelection() {
    const dispatch = useDispatch();
    const open = useSelector(state => state.app.showAutomaticSelection);
    const onClose = () => dispatch(setShowAutomaticSelection(false));

    const [quantity, setQuantity] = React.useState(automaticOptions[0]);

    const handleAutomatic = () => {
        onClose();
        dispatch(setShowLoading(true));
        dispatch(automaticChosen(quantity));
    }

    return (<Dialog
        open={open}
        onClose={onClose}
        maxWidth={"xs"}
        fullWidth
    >
        <DialogTitle>Selección automática</DialogTitle>
        <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8
            }}
        >
            <CloseIcon />
        </IconButton>
        <DialogContent>
            <TextField
                required
                fullWidth
                label="Cantidad de números"
                select
                value={quantity}
                onChange={(event) => setQuantity(event.target.value)}
            >
                {automaticOptions.map((option) => (
                    <MenuItem key={option} value={option}>
                        {option}
                    </MenuItem>
                ))}
            </TextField>
        </DialogContent>
        <DialogActions sx={{ p: 3 }}>
            <Button onClick={onClose}>Cerrar</Button>
            <Button onClick={handleAutomatic} startIcon={<AutoFixHighIcon />} variant='contained' color='primary'>Generar</Button>
        </DialogActions>
    </Dialog>)
}