import * as React from 'react';
import Box from '@mui/material/Box';
import { Container, Divider, Link, Stack, Typography } from '@mui/material';
import DevicesIcon from '@mui/icons-material/Devices';
import AppleIcon from '@mui/icons-material/Apple';
import AppShortcutIcon from '@mui/icons-material/AppShortcut';
import AppBlockingIcon from '@mui/icons-material/AppBlocking';
import { useDispatch, useSelector } from 'react-redux';

function Copyright() {
    return (
        <Typography variant="body2" color="text.secondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="mailto:sprew@googlegroups.com">
                sprew
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export default function Footer(props) {
    const { description, title } = props;
    const dispatch = useDispatch();
    const appState = useSelector(state => state.app);

    return (
        <Box component="footer" sx={{ mt: 2, p: 2 }}>
            <Container maxWidth="lg">
                <Typography variant="h6" align="center" color='primary' gutterBottom sx={{ mb: 0 }}>
                    {title}
                </Typography>
                <Typography
                    variant="subtitle1"
                    align="center"
                    color="text.secondary"
                    component="p"
                >
                    {description}
                </Typography>
                <Stack direction={{ xs: 'column', sm: 'row' }} alignItems='center' justifyContent={'center'} spacing={{ xs: 0, sm: 1 }} >
                    <Link href="/AvisoPrivacidad.pdf" sx={{ fontSize: 14 }}>Aviso de privacidad</Link>
                    <Link href="/TerminosCondiciones.pdf" sx={{ fontSize: 14 }}>Términos y condiciones</Link>
                </Stack>
                <Copyright />
                <Stack direction={'row'} alignItems='center' justifyContent={'center'} spacing={1} >
                    {appState.isIos ? <AppleIcon /> : <DevicesIcon />}
                    {appState.isStandalone ? <AppShortcutIcon /> : <AppBlockingIcon />}
                </Stack>
            </Container>
        </Box>
    );
}