import * as React from 'react';
import { Stack, TextField, Grid, Typography, MenuItem } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setUserData } from './chooseContactDataSlice';

const states = [
    'Aguascalientes', 'Baja California', 'Baja California Sur', 'Campeche', 'Chiapas', 'Chihuahua', 'Ciudad de México', 'Coahuila', 'Colima', 'Durango', 'Estado de México', 'Guanajuato', 'Guerrero', 'Hidalgo', 'Jalisco', 'Michoacán', 'Morelos', 'Nayarit', 'Nuevo León', 'Oaxaca', 'Puebla', 'Querétaro', 'Quintana Roo', 'San Luis Potosí', 'Sinaloa', 'Sonora', 'Tabasco', 'Tamaulipas', 'Tlaxcala', 'Veracruz', 'Yucatán', 'Zacatecas'
];

export default function ChooseContactData() {
    const dispatch = useDispatch();
    const selectedNumbers = useSelector(state => state.choose.selectedNumbers);
    const eventState = useSelector(state => state.event);
    const { userData } = useSelector(state => state.chooseContactData);

    return <Grid container spacing={2} >
        <Grid item xs={12} md={6}>
            <TextField
                required
                fullWidth
                label="Nombre"
                error={!userData.name?.trim().length}
                helperText={!userData.name?.trim().length ? 'Requerido' : ''}
                value={userData.name}
                onChange={(event) => {
                    dispatch(setUserData({ name: event.target.value }));
                }}
            />
        </Grid>
        <Grid item xs={12} md={6}>
            <TextField
                required
                fullWidth
                label="Apellidos"
                error={!userData.lastName?.trim().length}
                helperText={!userData.lastName?.trim().length ? 'Requerido' : ''}
                value={userData.lastName}
                onChange={(event) => {
                    dispatch(setUserData({ lastName: event.target.value }));
                }}
            />
        </Grid>
        <Grid item xs={12} md={6}>
            <TextField
                required
                fullWidth
                label="Teléfono"
                type='tel'
                error={userData.phone.replaceAll(/[\- ]/g, '').length < 10 || userData.phone.replaceAll(/[\- ]/g, '').length > 10}
                helperText={userData.phone.replaceAll(/[\- ]/g, '').length < 10 || userData.phone.replaceAll(/[\- ]/g, '').length > 10 ? 'Número a 10 dígitos' : ''}
                value={userData.phone}
                onChange={(event) => {
                    dispatch(setUserData({ phone: event.target.value }));
                }}
            />
        </Grid>
        <Grid item xs={12} md={6}>
            <TextField
                required
                fullWidth
                label="Estado"
                select
                error={userData.state?.length < 10}
                helperText={userData.state?.length < 10 ? 'Seleccione uno' : ''}
                value={userData.state}
                onChange={(event) => {
                    dispatch(setUserData({ state: event.target.value }));
                }}
            >
                {states.map((option) => (
                    <MenuItem key={option} value={option}>
                        {option}
                    </MenuItem>
                ))}
            </TextField>
        </Grid>
        <Grid item xs={12}>
            <Stack direction='row' justifyContent="flex-end" alignItems="center" spacing={2}>
                <Typography variant='h6' color={'text.secondary'}>Total</Typography>
                <Typography variant='h5' color={'primary'}>$ {(selectedNumbers.length * eventState.tickets.price).toFixed(2)} {eventState.currency ?? 'MXN'}</Typography>
            </Stack>
        </Grid>
    </Grid >;
}