import * as React from 'react';
import { Card, Stack, Typography, LinearProgress, Box, Slider, Stepper, Step, StepLabel, StepIcon } from '@mui/material';
import { animated, useSpring } from 'react-spring';
import { useSelector } from 'react-redux';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import { eventSlice } from './_eventSlice';

const AnimatedNumber = ({ from, to }) => {
    const { number } = useSpring({
        from: { number: from || 0 },
        number: to,
        delay: 200,
        config: {
            mass: 1,
            tension: 20,
            friction: 10
        }

    })

    return <animated.div>{number.to(n => Number(n.toFixed(0)).toLocaleString('en', { minimumFractionDigits: 0 }))}</animated.div>
}

export default function Progress() {
    const closeDate = useSelector(state => state.event.closeDate);
    const eventPrizes = useSelector(state => state.event.prizes);
    const eventDaysLeft = useSelector(state => state.event.daysLeft);
    const eventTicketsSettled = useSelector(state => state.event.tickets.settled);

    const stepProgress = Object.entries(eventPrizes).sort((a, b) => a[1] - b[1]);
    const activeStepProgressList = stepProgress.filter(([_, min]) => min <= eventTicketsSettled);
    const activeStepProgress = activeStepProgressList.length;
    const [dateStr, setDateStr] = React.useState('');

    React.useEffect(() => {
        const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
        const date = new Date(closeDate);
        setDateStr(`${date.getDate()} de ${months[date.getMonth()]} de ${date.getFullYear()}`);
    }, [closeDate]);

    return (<>
        <Card
            id="progress"
            sx={{
                p: 2
            }}
        >
            <Box sx={{ width: '100%' }}>
                {/* <AnimatedLinearProgress variant="determinate" value={50} /> */}
                <Stepper activeStep={activeStepProgress} alternativeLabel>
                    {stepProgress.map(([label]) => (
                        <Step key={label}>
                            <StepLabel StepIconComponent={({ active, completed }) =>
                                <StepIcon active={active} completed={completed} icon={(completed ?
                                    <CheckBoxIcon color='primary' /> :
                                    active ? <HourglassBottomIcon /> : <CheckBoxOutlineBlankIcon color='disabled' />)
                                } />}>
                                {label}
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Box>
            <Stack
                direction={{ xs: 'column', sm: 'row', md: 'column' }}
                justifyContent="space-between"
                alignItems="baseline"
                spacing={2}
            >
                <Stack spacing={0}>
                    {/* <Typography noWrap variant="h4" color="primary">Reloj inteligente</Typography> */}
                    <Typography noWrap variant="p" gutterBottom>Progreso de premios</Typography>
                </Stack>
                <Stack spacing={0}>
                    <Typography noWrap variant="h4"><AnimatedNumber to={eventTicketsSettled ?? 0} /></Typography>
                    <Typography noWrap variant="p" gutterBottom>Participación</Typography>
                </Stack>
                <Stack spacing={0}>
                    <Typography noWrap variant="h4"><AnimatedNumber from={365} to={(eventDaysLeft ?? 0) === 0 ? 'Es hoy!' : eventDaysLeft ?? 0} /></Typography>
                    <Typography noWrap variant="p" gutterBottom>Días restantes</Typography>
                </Stack>
                <Stack spacing={0}>
                    <Typography noWrap variant="h5">{dateStr}</Typography>
                    <Typography noWrap variant="p" gutterBottom>Fecha</Typography>
                </Stack>
            </Stack>
        </Card>
    </>)

}