import { configureStore } from '@reduxjs/toolkit'
import app from '../features/app/_appSlice'
import event from '../features/event/_eventSlice'
import choose from '../features/event/chooseSlice'
import { chooseContactData } from '../features/choose'
import { separated } from '../features/numbers'


export const store = configureStore({
  reducer: {
    app,
    event,
    choose,
    chooseContactData,
    separated
  }
})
