import * as React from 'react';
import { AppBar as MuiAppBar, Toolbar, IconButton, Container, Button, Box, Stack, Avatar, Menu, MenuItem, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux'
import { setShowPaymentOptions, setOnLandingPage } from './_appSlice'
import { setOpen as setOpenSeparated } from '../numbers/separatedSlice'
import MoreVertIcon from '@mui/icons-material/MoreVert';


const AppBar = () => {
    const dispatch = useDispatch();
    const appState = useSelector(state => state.app);

    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const handleShowPaymentOptions = () => dispatch(setShowPaymentOptions(true));
    const handleShowSeparatedNumbers = () => dispatch(setOpenSeparated(true));

    const handleOpenNavMenu = (event) => setAnchorElNav(event.currentTarget);
    const handleCloseNavMenu = (event) => {
        const { open } = event.currentTarget.dataset;
        if (open === 'paymentOptions') handleShowPaymentOptions();
        if (open === 'separatedNumbers') handleShowSeparatedNumbers();
        setAnchorElNav(null);
    }

    return (
        <div>
            <MuiAppBar
                position="relative"
                color="primary"
                sx={{ mb: 0 }}
            >
                <Container>
                    <Toolbar
                        sx={{
                            height: 108,
                        }}>
                        <Avatar
                            sx={{
                                cursor: 'pointer',
                                width: 92,
                                height: 92,
                                position: "absolute",
                                zIndex: 1,
                                top: 8,
                                left: 0,
                                right: 0,
                                margin: { xs: "0 auto", md: 0 },
                                color: 'primary'
                            }}
                            elevation={1}
                            src={appState.logo}
                            onClick={() => dispatch(setOnLandingPage(true))}
                        />
                        <Box sx={{ flexGrow: 1 }}></Box>
                        <Stack direction='row' sx={{ display: { xs: "none", md: "flex" } }}>
                            <Button color="inherit" onClick={handleShowPaymentOptions}>Pago</Button>
                            <Button color="inherit" onClick={handleShowSeparatedNumbers}>Apartados</Button>
                            <Button color="inherit" href='#FAQs'>Dudas</Button>
                        </Stack>
                        <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MoreVertIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                }}
                            >
                                <MenuItem data-open="paymentOptions" onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center">Pago</Typography>
                                </MenuItem>
                                <MenuItem data-open="separatedNumbers" onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center">Apartados</Typography>
                                </MenuItem>
                                <MenuItem component='a' href="#FAQs" onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center">Dudas</Typography>
                                </MenuItem>
                            </Menu>
                        </Box>

                    </Toolbar>
                </Container>
            </MuiAppBar>
        </div >
    );
}

export default AppBar