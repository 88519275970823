import * as React from 'react';
import { CardMedia, Container, Grid, Stack } from '@mui/material';
import { useSelector } from 'react-redux'
import { Progress, WantWinButton } from '@features/event'

export default function Event() {
    const eventStateImage = useSelector(state => state.event.image);

    return (<>
        <CardMedia
            component="img"
            sx={{
                width: '100%',
                display: { xs: 'block', md: 'none' }
            }}
            image={eventStateImage}
            alt="Imagen del sorteo"
        />
        <Container maxWidth="lg">
            <Grid container spacing={2} >
                {/* <Titles sx={{
                    display: {
                        xs: 'none', md: 'flex'
                    },
                }} /> */}
                <Grid item xs={12} md={7}>
                    <CardMedia
                        component="img"
                        sx={{
                            width: '100%',
                            display: { xs: 'none', md: 'block' },
                            mb: 1
                        }}
                        image={eventStateImage}
                        alt="Imagen del sorteo"
                    />
                </Grid>
                {/* <Titles sx={{
                    display: {
                        xs: 'flex', md: 'none'
                    },
                }} /> */}
                <Grid item xs={12} md={5} >
                    <Stack direction={{ xs: 'column-reverse', md: 'column' }}>
                        <Progress />
                        <WantWinButton />
                    </Stack>
                </Grid>
            </Grid>
        </Container>
    </>
    )
}
