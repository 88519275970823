import * as React from 'react';
import { CardMedia, Container, Button, Stack, Typography } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux'
import { setOnLandingPage } from './_appSlice'

export default function LandingPage() {
    const dispatch = useDispatch();
    const appState = useSelector(state => state.app);

    return (
        <Container
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                pt: { xs: 14, sm: 20 },
                pb: { xs: 8, sm: 12 },
            }}
        >
            <CardMedia
                component="img"
                sx={{
                    cursor: 'pointer',
                    width: { xs: 198, sm: 256 },
                    height: { xs: 198, sm: 256 }
                }}
                image={appState.logo}
                onClick={() => dispatch(setOnLandingPage(true))}
            />
            <Stack spacing={2} sx={{ width: { xs: '100%', sm: '70%' } }}>
                <Typography
                    component="h1"
                    variant="h2"
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        alignSelf: 'center',
                        textAlign: 'center',
                    }}
                    color="primary"
                >
                    {appState.title}
                </Typography>
                <Typography variant="body1" textAlign="center" color="text.secondary">
                    {appState.subtitle}
                </Typography>
                <Stack
                    direction={{ xs: 'column', sm: 'row' }}
                    alignSelf="center"
                    spacing={1}
                    sx={{ pt: 2, width: { xs: '100%', sm: 'auto' } }}
                >
                    <Button variant="contained" color="primary" onClick={() => dispatch(setOnLandingPage(false))}>
                        Mas información
                    </Button>
                </Stack>
            </Stack>
        </Container>
    );
}
