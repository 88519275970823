import * as React from 'react';
import { Typography, Paper, Link, Grid, Container } from '@mui/material';
import { useDispatch } from 'react-redux';
import { setShowPaymentOptions } from './_appSlice';
import { setOpen as setOpenSeparated } from '../numbers/separatedSlice'
import MoreVertIcon from '@mui/icons-material/MoreVert';

export default function Faq() {
    const dispatch = useDispatch();
    const handlePaymentOptions = () => dispatch(setShowPaymentOptions(true));

    return <Paper sx={{ mt: 2, pb: 2 }} id="FAQs">
        <Container>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography component='h2' variant='h4' color='primary' >Preguntas frecuentes</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant='h6' color='text.secondary'>¿Qué es sorteo efectivo?</Typography>
                    <Typography align="justify">
                        Es una plataforma web dedicada al sorteo entre amigos de artículos de interés, pudiendo ser relojes, televisiones, tabletas, equipo de cómputo, tarjetas de regalo, teléfonos, estos premios se definen en cada sorteo y en caso de resultar ganador estos no pueden ser canjeados por dinero.
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant='h6' color='text.secondary'>¿Puedo realizar abonos?</Typography>
                    <Typography align="justify">
                        Si, para no perder tu participación y extender su tiempo de vida reservado puede realizar abonos, vea su saldo pendiente presionando en {<MoreVertIcon fontSize='inherit' />} / Apartados o el botón para ver el <Link onClick={() => dispatch(setOpenSeparated(true))} href="#history">historial de participación</Link>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant='h6' color='text.secondary'>¿Cómo pago/abono?</Typography>
                    <Typography align="justify">
                        Al confirmar su participación se mostrará un botón con la leyenda pagar qué abrirá las <Link onClick={handlePaymentOptions} href="#payment-forms">opciones de pago.</Link>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant='h6' color='text.secondary'>¿Ya pagué/aboné, no se refleja?</Typography>
                    <Typography align="justify">
                        Nuestro sistema está automatizado, este puede tardar hasta un máximo de 10 min en reflejar la transacción realizada en su referencia. Si ya pasó ese tiempo y sigue sin reflejarse,
                        usando nuestras redes sociales o el correo sorteoefectivo@googlegroups.com, envíenos un mensaje cón la referencia de pago, fecha en que se realizo la transacción y el monto.
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant='h6' color='text.secondary'>¿Cual es mi comprobante?</Typography>
                    <Typography align="justify">
                        Presiona en {<MoreVertIcon fontSize='inherit' />} / Apartados o el botón para ver el <Link onClick={() => dispatch(setOpenSeparated(true))} href="#history">historial de participación</Link>, cada participación pagada mostrará un folio único que sirve para verificar que tu eres el propietario de esa selección numérica.
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant='h6' color='text.secondary'>¿Qué son las participaciones?</Typography>
                    <Typography align="justify">
                        Nuestros sorteos son efectivos, es decir el premio se garantiza según la participación, en el <Link href="#progress">progreso de premios</Link> se desbloquearan los premios totales a entregar al ganador.
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant='h6' color='text.secondary'>¿Cómo se selecciona al ganador?</Typography>
                    <Typography align="justify">
                        La fecha del sorteo esta ligada al calendario de la <Link href='https://www.facebook.com/LoteriaNacional.Mx' target="_blank">Lotería Nacional para la Asistencia Pública Mexicana</Link> al tipo de sorteo Sorteo Superior celebrado cada viernes, para ser ganador deberá de coincidir exactamente o en terminación (en caso de ser una numeración inferior a la del premio mayor) el numero del premio mayor y la fecha del sorteo.
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant='h6' color='text.secondary'>¿Qué pasa cuando gana alguien?</Typography>
                    <Typography align="justify">
                        Una vez haya ganador, el administrador se pondrá en contacto el para coordinar la entrega del premio correspondiente. Es importante colocar correctamente nombre, apellido, número telefónico y estado vigente.
                        También publicaremos en nuestras redes sociales el numero ganador.
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant='h6' color='text.secondary'>¿Qué pasa cuando nadie gana?</Typography>
                    <Typography align="justify">
                        Cuando el numero del premio mayor no haya sido adquirido por alguien el sorteo se cambiara a la siguiente fecha esto se podrá realizar hasta por un máximo de 3 ocasiones.
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    </Paper>
}