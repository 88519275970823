import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { collection, getDocs, query, where, limit, doc, updateDoc } from "firebase/firestore";
import { firestore, messaging } from '../../app/firebase';
import { loadEvent } from '../event/_eventSlice'
import { getToken } from 'firebase/messaging';
import { enqueueSnackbar } from 'notistack';

const initialState = {
    status: 'idle',
    permissionStatus: 'prompt',
    showLoading: true,
    load: false,
    domain: undefined,
    onLandingPage: true,
    message: 'Validando host',
    error: '',
    showPaymentOptions: false,
    showIosInstallation: false,
    showAutomaticSelection: false,
    showNumbers: false,
    firstTime: true,
    tokenId: undefined,
    sessionId: undefined,
    permission: undefined,
    isIos: false,
    isStandalone: false,
}


const getPWADisplayMode = () => {
    const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
    if (document.referrer.startsWith('android-app://')) {
        return 'twa';
    } else if (navigator.standalone || isStandalone) {
        return 'standalone';
    }
    return 'browser';
}

export const isIos = /iphone|ipad|ipod/.test(window.navigator.userAgent.toLowerCase());
export const isStandalone = getPWADisplayMode() !== 'browser';

export const hasNotifications = () => 'Notification' in window;
export const getNotificationPermission = () => {
    if (hasNotifications()) return Notification.permission;
    const notificationPermission = window.localStorage.getItem('notificationPermission');
    if (notificationPermission) return notificationPermission;
    return 'prompt';
}

export const setNotificationPermission = (permission) => {
    window.localStorage.setItem('notificationPermission', permission);
}

const getCaches = () => caches.open('app');

const cacheHeaders = () => {
    const headers = new Headers();
    headers.append('Content-Type', 'text/plain');
    return headers;
}

const getSession = async () => {
    const cache = await getCaches();
    let sessionId = await cache.match('/sessionId')
    if (sessionId) return sessionId.text();

    sessionId = doc(collection(firestore, 'id')).id;
    await cache.put('/sessionId', new Response(sessionId, { headers: cacheHeaders() }))
    return sessionId;
}

const getFirstTime = async () => {
    const cache = await getCaches();
    if (await cache.match('/firstTime')) return false;
    await cache.put('/firstTime', new Response('no', { headers: cacheHeaders() }))
    return true;
}

export let loadApp = createAsyncThunk('app/load', async (_, { rejectWithValue, dispatch }) => {
    const domainsQry = query(
        collection(firestore, "domains"),
        where("hosts", "array-contains", window.location.host.split("--")[0]),
        limit(1)
    );
    const domainSnapshot = await getDocs(domainsQry);
    if (domainSnapshot.empty) return rejectWithValue('Domain not found');
    const domain = domainSnapshot.docs[0].id;
    dispatch(loadEvent(domain));
    var data = domainSnapshot.docs[0].data();
    if (data.title) document.title = data.title;

    const sessionId = await getSession();
    const firstTime = await getFirstTime();

    return {
        ...data,
        domain,
        sessionId,
        permissionStatus: getNotificationPermission(),
        firstTime,
        isIos,
        isStandalone,
    }
})

export let requestPermission = createAsyncThunk('app/permission', async (_, { rejectWithValue, dispatch }) => {
    const permissionResult = await Notification.requestPermission();
    if (permissionResult === 'granted') return permissionResult;
    return rejectWithValue('rejected');
})

export let requestToken = createAsyncThunk('app/token', async (_, { getState, rejectWithValue }) => {
    const { app: { sessionId } } = getState();
    const token = await getToken(messaging, { vapidKey: "BLG_Wp4FygZDrLzo6JW5K3PEX8wNkM-zXpbKIAiU2NCLD_fOvSNp-tm5TR4TNlbyPkA7HPOtRk6Qa1gDJZzVMrg" })
    if (token) {
        await updateDoc(doc(firestore, 'sessions', sessionId), { token });
        enqueueSnackbar('Notificaciones activadas', { variant: 'success', autoHideDuration: 1000 });
        return token;
    }
    enqueueSnackbar('Ops! ' + token, { variant: 'error', autoHideDuration: 1000 });
    return rejectWithValue(token);
});

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setOnLandingPage: (state, action) => { state.onLandingPage = action.payload || false },
        setPermission: (state, action) => {
            setNotificationPermission(action.payload);
            if (action.payload === 'reject') state.tokenId = undefined;
            state.permissionStatus = action.payload;
        },
        setShowAutomaticSelection: (state, action) => { state.showAutomaticSelection = action.payload || false },
        setShowIosInstallation: (state, action) => { state.showIosInstallation = action.payload || false },
        setShowLoading: (state, action) => { state.showLoading = action.payload || false },
        setShowNumbers: (state, action) => { state.showNumbers = action.payload || false },
        setShowPaymentOptions: (state, action) => { state.showPaymentOptions = action.payload || false },
    },
    extraReducers(builder) {
        builder
            .addCase(loadApp.pending, (state) => {
                state.status = 'loading'
                state.load = false;
                state.message = 'Consultando datos...'
            })
            .addCase(loadApp.fulfilled, (state, action) => ({
                ...state,
                ...action.payload,
                status: 'succeeded',
                load: true,
                message: 'Listo',
            }))
            .addCase(loadApp.rejected, (state, action) => {
                state.status = 'failed'
                state.load = false;
                state.error = action.payload || action.error.message
                enqueueSnackbar('Ops! ' + state.error, { variant: 'error' });
            })

            .addCase(requestPermission.pending, state => { setNotificationPermission('prompt'); state.permissionStatus = 'prompt' })
            .addCase(requestPermission.rejected, state => { setNotificationPermission('reject'); state.tokenId = undefined; state.permissionStatus = 'reject' })
            .addCase(requestPermission.fulfilled, state => { setNotificationPermission('granted'); state.permissionStatus = 'granted' })
            .addCase(requestToken.rejected, (state, action) => { state.tokenId = undefined; })
            .addCase(requestToken.fulfilled, (state, action) => { state.tokenId = action.payload })
    }
})

// Action creators are generated for each case reducer function
export const {
    setOnLandingPage,
    setPermission,
    setShowAutomaticSelection,
    setShowIosInstallation,
    setShowLoading,
    setShowNumbers,
    setShowPaymentOptions,
} = appSlice.actions

export default appSlice.reducer