import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { firestore } from '../../app/firebase';
import { collection, getDocs, query, where, limit } from "firebase/firestore";
import { setEventId } from './chooseSlice';
import { enqueueSnackbar } from 'notistack';

const initialState = {
    status: 'idle',
    numbersStatus: 'idle',
    error: '',
    load: false,
    numbers: [],
    selectedNumbers: []
}

export const loadEvent = createAsyncThunk('event/load', async (domain, { rejectWithValue, dispatch }) => {
    const eventsQry = query(
        collection(firestore, "events"),
        where("domain", "==", domain),
        where("latest", "==", true),
        where("public", "==", true),
        limit(1)
    );
    const eventsSnapshot = await getDocs(eventsQry);
    if (eventsSnapshot.empty) return rejectWithValue('Events not found');

    const id = eventsSnapshot.docs[0].id;

    dispatch(setEventId(id));
    const eventData = { ...eventsSnapshot.docs[0].data() };
    eventData.closeDate = eventData.closeDate.toDate().getTime();
    return {
        domain,
        ...eventData,
        id
    }
})

export const eventSlice = createSlice({
    name: 'event',
    initialState,
    reducers: {
        setNumbers: (state, action) => {
            state.numbersStatus = 'succeeded';
            state.numbers = action.payload;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(loadEvent.pending, (state) => {
                state.status = 'loading'
            })
            .addCase(loadEvent.fulfilled, (state, action) => ({
                ...state,
                ...action.payload,
                status: 'succeeded',
                load: true,
                message: 'Listo',
            }))
            .addCase(loadEvent.rejected, (state, action) => {
                state.status = 'failed'
                state.load = false;
                state.error = action.payload || action.error.message
                enqueueSnackbar('Ops! ' + state.error, { variant: 'error' });
            })
    }
})

export const {
    setNumbers,
} = eventSlice.actions

export default eventSlice.reducer