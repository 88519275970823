import * as React from 'react';
import { IconButton } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { enqueueSnackbar } from 'notistack';

export default function CopyButton({ copy, message }) {
    const handleCopy = () => navigator.clipboard.writeText(copy)
        .then(() => enqueueSnackbar(message, { variant: 'success' }))
        .catch(() => enqueueSnackbar('Ops! Algo fallo al copiar', { variant: 'error' }))

    return <IconButton aria-label="copy" size="small" onClick={handleCopy}>
        <ContentCopyIcon fontSize="inherit" />
    </IconButton>
};