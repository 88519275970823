import * as React from 'react';
import { Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions, Alert, useMediaQuery, useTheme, IconButton, Grid, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { setShowPaymentOptions } from './_appSlice';
import CopyButton from './CopyButton';

export default function PaymentOptions() {
    const dispatch = useDispatch();
    const appState = useSelector(state => state.app);
    const eventState = useSelector(state => state.event);
    const { addTakenStatus, reference, total } = useSelector(state => state.choose);
    const open = useSelector(state => state.app.showPaymentOptions);
    const paymentOptions = appState.paymentOptions;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const onClose = () => dispatch(setShowPaymentOptions(false));

    return (<Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth={"xs"}
        fullWidth
        fullScreen={fullScreen}

    >
        <DialogTitle id="scroll-dialog-title">Formas de pago</DialogTitle>
        <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
                position: 'absolute',
                right: 8,
                top: 8
            }}
        >
            <CloseIcon />
        </IconButton>
        <DialogContent
            sx={{
                p: 0,
                m: 0
            }}>
            {addTakenStatus !== 'fulfilled' && <Alert severity="warning">No olvide verificar su referencia numérica de 7 dígitos, esta deberá ser colocada en la referencia o descripción de su transferencia.</Alert>}
            {addTakenStatus === 'fulfilled' && <Alert severity="info" sx={{ mb: 1 }}>
                Referencia de pago: <Typography component="b" variant="b" sx={{ letterSpacing: 2 }}>{reference} <CopyButton copy={reference.toString()} message={`Referencia ${reference} copiada`} /></Typography>
                <br />
                Total: <Typography component="b" variant="b" sx={{ letterSpacing: 2 }}>$ {(total).toFixed(2)} {eventState.currency ?? 'MXN'} <CopyButton copy={total.toFixed(2)} message={`Total de ${total.toFixed(2)} copiado`} /></Typography>
            </Alert>}
            {paymentOptions.transfer && paymentOptions.transfer.length > 0 &&
                <div>
                    <Alert severity="info">Pago solo por transferencia</Alert>
                    <Grid sx={{ p: 1 }} container spacing={2}>{paymentOptions.transfer.map((transferItem, i) =>
                        <Grid item xs={12} key={i}>
                            <Stack direction={'row'} spacing={2} alignItems={'center'} justifyContent={'center'}>
                                <Typography>{transferItem.bank}</Typography>
                                <Stack>
                                    {transferItem.clabe && <Typography sx={{ letterSpacing: 1.5 }}>{transferItem.clabe} <CopyButton copy={transferItem.clabe} message={`CLABE ${transferItem.clabe} copiada`} /></Typography>}
                                    {transferItem.card && <Typography sx={{ letterSpacing: 1.5 }}>{transferItem.card} <CopyButton copy={transferItem.card} message={`Tarjeta ${transferItem.card} copiada`} /></Typography>}
                                    {transferItem.clabe && <Typography sx={{ fontSize: 12, mt: -1 }} color={'text.secondary'}>CLABE interbancaria</Typography>}
                                    {transferItem.card && <Typography sx={{ fontSize: 12, mt: -1 }} color={'text.secondary'}>Número de tarjeta</Typography>}
                                    <Typography>{transferItem.recipient} <CopyButton copy={transferItem.recipient} message={`Beneficiario ${transferItem.recipient} copiado`} /></Typography>
                                    <Typography sx={{ fontSize: 12, mt: -1 }} color={'text.secondary'}>Beneficiario</Typography>
                                </Stack>
                            </Stack>
                        </Grid>
                    )}
                    </Grid>
                </div>
            }
            {/* {paymentOptions.convenience_store && paymentOptions.convenience_store.length > 0 &&
                <div>
                    <Alert severity="info">Pago solo en tienda de conveniencia o ventanilla</Alert>
                    <List>
                        {paymentOptions.convenience_store.map((convenience_store, i) =>
                            <ListItem key={i}
                                sx={{ pt: 0, pb: 0 }}
                                dense
                                divider
                            >
                                <Bank bank={convenience_store.bank} />
                                <List dense sx={{ pt: 0, pb: 0 }}>
                                    {convenience_store.accounts.map((item, j) =>
                                        <ListItemText key={j}
                                            primary={item}
                                        />)}
                                </List>
                            </ListItem>
                        )}
                    </List>
                </div>
            } */}
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
            <Button onClick={onClose}>Enterado</Button>
        </DialogActions>
    </Dialog>)
}