import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { database, firestore } from '../../app/firebase';
import { collection, doc, increment as incrementDoc, updateDoc, serverTimestamp as serverTimestampDoc, setDoc } from "firebase/firestore";
import { timeout } from '@app/utils';
import { onValue, ref, update, runTransaction, serverTimestamp as serverTimestampDb, remove, off } from 'firebase/database';
import { setNumbers } from './_eventSlice';
import { enqueueSnackbar } from 'notistack';
import { readUserData } from '@features/choose/chooseContactDataSlice';

const initialState = {
    status: 'idle',
    chosenStatus: 'pending',
    automaticChosenStatus: 'pending',
    selectedStatus: 'idle',
    addTakenStatus: 'idle',
    error: '',
    step: 0,
    total: 0,
    id: '',
    selectedNumbers: [],
}

export const listeningChosen = createAsyncThunk('chosen/listening', async (_, { getState, dispatch }) => {
    const { choose } = getState();
    dispatch(readUserData())
    onValue(ref(database, `chosen/${choose.eventId}/${choose.id}`), (snapshot) => {
        if (getState().choose.selectedStatus !== 'selecting') return;
        if (getState().choose.step > 0) return;
        if (!snapshot.val()) {
            alert('Tiempo excedido en la selección de números. Inténtalo de nuevo.');
            window.location.reload();
        }
    });
    onValue(ref(database, `numbers/${choose.eventId}`), (snapshot) => {
        const data = snapshot.val();
        if (!data) return;
        var entries = Object.entries(data).map(([number, t]) => [number, !t]).sort(([a], [b]) => a - b);
        dispatch(setListening());
        dispatch(setNumbers(entries));
    }, { onlyOnce: true });
});

/**
 * 
 * @param {string} domain 
 * @returns {Promise<number>}
 */
const getReference = async (domain) => {
    const domainRef = ref(database, `domain/${domain}`);
    const result = await runTransaction(domainRef, (domainCurrentData) => {
        if (!domainCurrentData) domainCurrentData = { reference: 1000000 };
        if (domainCurrentData) domainCurrentData.reference++;
        return domainCurrentData;
    });
    return (result.committed && result.snapshot.val().reference) || undefined;
}

export const addTaken = createAsyncThunk('taken/add', async ({ numbers, userInfo, total }, { getState, dispatch, rejectWithValue }) => {
    const { choose: { eventId, id: chooseId }, app: { domain, sessionId, tokenId }, event: { closeDate } } = getState();
    off(ref(database, `numbers/${eventId}`));
    off(ref(database, `chosen/${eventId}/${chooseId}`));
    await timeout(500);
    dispatch(setStatus('idle'));
    dispatch(setChosenStatus('pending'));
    const reference = await getReference(domain);
    if (!reference) return rejectWithValue('Error al obtener referencia');


    await remove(ref(database, `chosen/${eventId}/${chooseId}`));
    await update(ref(database, `taken/${eventId}`), {
        [chooseId]: {
            timestamp: serverTimestampDb(),
            numbers,
            userInfo,
            total,
            reference: reference.toString()
        }
    });

    await setDoc(doc(firestore, 'references', reference.toString()), {
        total,
        sessionId,
        chooseId,
        settled: false,
        paid: 0,
        eventId,
        eventDate: closeDate,
        items: numbers.length,
        timestamp: serverTimestampDoc(),
        token: tokenId !== undefined && tokenId !== ''
    })
    await setDoc(doc(firestore, 'sessions', sessionId), { ...userInfo });
    await setDoc(doc(firestore, 'sessions', sessionId, 'chosen', chooseId), {
        numbers,
        total,
        userInfo,
        reference,
        paid: 0,
        settled: false,
        timestamp: serverTimestampDoc()
    });

    const eventDocRef = doc(firestore, 'events', eventId);
    await updateDoc(eventDocRef, {
        "tickets.separated": incrementDoc(numbers.length)
    })
    return { reference, total };
});

export const freeChosen = createAsyncThunk('chosen/free', async (number, { getState }) => {
    const { choose } = getState();
    await update(ref(database, 'event/' + choose.eventId + '/numbers'), { [number]: false });
});

export const addChosen = createAsyncThunk('chosen/add', async (number, { getState, rejectWithValue }) => {
    const { choose: { eventId, id: chooseId }, event, app: { sessionId } } = getState();
    off(ref(database, `numbers/${eventId}`));
    off(ref(database, `chosen/${eventId}/${chooseId}`));
    await timeout(100);
    const index = event.numbers.findIndex(([n]) => n === number);
    if (event.numbers[index] && !event.numbers[index][1]) return rejectWithValue('taken');
    const numberRef = ref(database, `numbers/${eventId}/${number}`);
    const result = await runTransaction(numberRef,
        (taken) => !taken || taken === null ? chooseId : undefined
    );
    if (!result.committed) return rejectWithValue('taken');
})

export const automaticChosen = createAsyncThunk('chosen/automatic', async (quantity, { getState, rejectWithValue }) => {
    const { choose: { eventId, id: chooseId }, event } = getState();
    off(ref(database, `numbers/${eventId}`));
    off(ref(database, `chosen/${eventId}/${chooseId}`));
    let automaticChosen = [];
    const max_attempts = 10;
    for (let i = 0; i < quantity; i++) {
        let result = {};
        let number = '';
        let attempts = 0;
        do {
            number = event.numbers[Math.floor(Math.random() * event.numbers.length)][0];
            const numberRef = ref(database, `numbers/${eventId}/${number}`);
            result = await runTransaction(numberRef,
                (taken) => !taken || taken === null ? chooseId : undefined
            );
            attempts++;
            if (attempts > max_attempts) return rejectWithValue('Error en la selección, pocos números disponibles.')
        } while (!result.committed);
        if (number)
            automaticChosen.push(number);
    }
    return automaticChosen;
});

export const updateChosen = createAsyncThunk('chosen/update', async (numbers, { getState }) => {
    const { choose } = getState();
    await update(ref(database, `chosen/${choose.eventId}/`), {
        [choose.id]: {
            timestamp: serverTimestampDb(),
            numbers
        }
    });
})

const _setPending = state => { state.chosenStatus = 'pending' };
const _setAutomaticPending = state => { state.automaticChosenStatus = 'pending' };

export const chooseSlice = createSlice({
    name: 'choose',
    initialState,
    reducers: {
        reset: state => ({ ...state, ...initialState }),
        resetTakenStatus: (state) => { state.addTakenStatus = 'idle' },
        setStep: (state, action) => { state.step = action.payload || 0 },
        setStatus: (state, action) => { state.status = action.payload },
        setListening: state => { state.status = 'listening' },
        setPending: _setPending,
        setAutomaticPending: _setAutomaticPending,
        setChosenStatus: (state, action) => { state.chosenStatus = action.payload },
        setSelectedStatus: (state, action) => {
            state.selectedStatus = action.payload
        },
        setId: state => {
            state.id = doc(collection(firestore, 'id')).id;
        },
        setEventId: (state, action) => {
            state.eventId = action.payload;
            state.id = doc(collection(firestore, 'id')).id;
        },
        setSelectedNumbers: (state, action) => {
            state.selectedNumbers = action.payload;
        },
    },
    extraReducers(builder) {
        builder.addCase(addChosen.pending, _setPending)
        builder.addCase(addChosen.fulfilled, (state) => { state.chosenStatus = 'success'; })
        builder.addCase(addChosen.rejected, (state) => { state.chosenStatus = 'rejected'; })
        builder.addCase(addTaken.pending, (state) => { state.addTakenStatus = 'pending'; })
        builder.addCase(addTaken.rejected, (state) => { state.addTakenStatus = 'rejected'; enqueueSnackbar('Ocurrió un error al reservar', { variant: 'error' }); })
        builder.addCase(addTaken.fulfilled, (state, action) => {
            state.reference = action.payload.reference;
            state.total = action.payload.total;
            state.addTakenStatus = 'fulfilled';
            enqueueSnackbar('Apartados con éxito!', { variant: 'success' });
        })
        builder.addCase(automaticChosen.pending, _setAutomaticPending)
        builder.addCase(automaticChosen.fulfilled, (state, action) => {
            state.automaticChosenStatus = 'success';
            state.selectedNumbers = action.payload;
        })
        builder.addCase(automaticChosen.rejected, (state) => { state.selectedNumbers = []; state.automaticChosenStatus = 'rejected'; enqueueSnackbar('Ocurrió un error, intenta la selección manual.', { variant: 'error' }); })
    }
})

export const {
    reset,
    resetTakenStatus,
    setAutomaticPending,
    setChosenStatus,
    setEventId,
    setId,
    setListening,
    setPending,
    setSelectedNumbers,
    setSelectedStatus,
    setStatus,
    setStep,
} = chooseSlice.actions

export default chooseSlice.reducer